import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
// import parse from "html-react-parser"
import { useIntl, Link } from "gatsby-plugin-react-intl"
import WhySection from "../../components/WhySection"

const OurTeamPage = ({ data: { page, teamMembers } }) => {
  const intl = useIntl()
  if (teamMembers.length <= 0) return

  return (
    <Layout>
      <h1 className="hidden">{page?.title}</h1>
      <Seo seoData={page?.seo} />
      <div className="w-full mx-auto text-center py-10">
        <h2 className="text-themePrimary text-5xl font-PTSerif font-bold tracking-wide text-center pt-10">
          {intl.formatMessage({ id: "Meet Our Team" })}
        </h2>
        <div className="w-10/12 mx-auto py-20 flex flex-col lg:flex-row justify-center gap-5">
          {teamMembers?.nodes?.map(teamMember => {
            return (
              <div
                key={teamMember.id}
                className="w-full text-center text-xl text-themePrimary"
              >
                <h2 className="pb-3">
                  <Link
                    className="font-PTSerif font-bold text-3xl text-themePrimary text-center hover:text-themeAccent transition delay-150 duration-300 ease-in-out"
                    to={teamMember.uri}
                  >
                    {teamMember.title}
                  </Link>
                </h2>
                <Link to={teamMember.uri}>
                  <GatsbyImage
                    alt={teamMember.featuredImage.node.altText}
                    image={
                      teamMember.featuredImage.node.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    className="hover:filter hover:brightness-50 transition delay-150 duration-300 ease-in-out"
                  />
                </Link>
              </div>
            )
          })}
        </div>
      </div>

      <WhySection />
    </Layout>
  )
}

export default OurTeamPage

export const pageQuery = graphql`
  query OurTeamPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      slug
      seo {
        fullHead
        schema {
          raw
        }
      }
    }
    teamMembers: allWpTeamMember(sort: { fields: id, order: ASC }) {
      nodes {
        id
        content
        title
        memberRole
        uri
        spanishBio {
          spanishBio
          spanishRole
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
